<template>
  <div class="container">
    <carouselBanner paddingTop="14%" :bannerType="10" />
    <div class="content_area" ref="list">
      <div class="clearfix">
        <div v-loading="loading">
          <div>
            <div class="item" v-for="(item, i) in dataList" :key="i">
              <div class="title" @click="handleContent(item)">
                {{ item.title }}
              </div>
              <div class="desc">
                <i class="el-icon-time"></i
                >{{ item.created_time | secondFormat("LL") }}
                <i class="el-icon-view" style="margin-left: 80px"></i>{{ item.num_view || 0 }}
              </div>
            </div>
            <div class="nodataBox" v-if="!loading && dataList.length == 0">
              <noDataImg />
            </div>
          </div>
          <div class="pagination">
            <el-pagination
              layout="prev, pager, next"
              :total="total"
              background
              :current-page.sync="params.start"
              :page-size="10"
              :hide-on-single-page="true"
              @current-change="currentChange"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import carouselBanner from "@/baseComponents/carouselBanner";
export default {
  metaInfo: {
    title: "信息公示",
  },
  components: {
    carouselBanner,
  },
  data() {
    return {
      dataList: [],
      params: {
        start: 1,
        limit: 10,
        nopage: 0,
      },
      total: 0,
      loading: false,
    };
  },
  mounted() {
    this.getDataList();
  },
  methods: {
    async getDataList() {
      this.loading = true;
      let params = {
        content_type: 100, // content_type: 100  信息公示专用
        source: this.PJSource,
        nopage: this.params.nopage,
        limit: this.params.limit,
        start: this.params.start - 1,
        is_show: 1,
        language: this.LOCALE === "en" ? 1 : 2,
      };
      let res = await this.$store.dispatch(
        "API_index/getStaticContent",
        params
      );
      this.loading = false;
      if (res && res.success) {
        this.dataList = res.data;
        this.total = res.total;
      }
    },
    handleContent(item) {
      // if (this.IS_LOGIN) {
        this.$router.push({
          path: "/makePublicDetail",
          query: {
            parameter: this._encode({
              detailID: item.id,
            }),
          },
        });
      // } else {
      //   this.$GLOBALEVENT.$emit("SHOWGLOBALLOGIN", {
      //     waitTodo: () => {
      //       this.$router.push({
      //         path: "/makePublicDetail",
      //         query: {
      //           parameter: this._encode({
      //             detailID: item.id,
      //           }),
      //         },
      //       });
      //     },
      //   });
      // }
    },
    currentChange(index) {
      this.$nextTick(() => {
        this.$refs.list.scrollIntoView();
      });
      this.params.start = index;
      this.getDataList();
    },
  },
};
</script>

<style scoped lang="less">
.container {
  background: #f2f4f6;
  .content_area {
    .item {
      width: 1200px;
      height: 86px;
      padding: 0 16px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      background: #ffffff;
      border-radius: 4px;
      margin-bottom: 16px;
      .title {
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 16px;
        font-weight: bold;
        color: #333;
        &:hover {
          color: #409eff;
          cursor: pointer;
        }
      }
      .desc {
        font-size: 12px;
        color: #999;
        i {
          margin-right: 7px;
        }
      }
    }
  }
  .nodataBox {
    height: 400px;
    width: 200px;
    margin: auto;
    position: relative;
    text-align: center;
  }
}

.pagination {
  margin: 20px auto 60px;
  display: flex;
  flex-direction: row-reverse;
}
</style>